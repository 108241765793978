:root
  --item-height: 2rem
  --item-margin-bottom: .5rem
  --border-radius: 5px

.skeleton
  height: var(--item-height)

  &:not(:last-child)
    margin-bottom: var(--item-margin-bottom)

.h1
  text-align: center
  padding: .5rem .8rem

.divider
  border-color: #444
  border-width: 2px
  border-radius: 5px
  margin-bottom: 1rem

.search
  // background-color: #fff
  margin-bottom: 2rem

.list
  border: 2px solid #444
  border-radius: 5px
  padding: 2rem 0
