.main
  font-size: var(--font-size-h1)
  height: 100vh
  align-items: ceter
  justify-content: center

.form
  border: 2px solid #ddd
  border-radius: 5px
  padding: 0 3rem

  & > *:last-child
    margin-bottom: 2rem

  & > *:first-child
    margin-top: 2rem

.logo
  max-height: 8rem
  margin-bottom: 2rem

.legend
  font-weight: 700
  font-size: var(--chakra-fontSizes-lg)
