.card
  border: 2px solid #ddd
  border-radius: 5px
  padding: 2rem
  min-width: 30rem

  &:first-child
    margin-top: 4rem

  &:not(:last-child)
    margin-bottom: 2rem

.stats
  margin-top: 2rem

.h1
  margin-bottom: 2rem

.row:not(:last-child)
  margin-bottom: 1rem
