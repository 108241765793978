.project
  display: inline-flex
  justify-content: center
  align-items: center

  &__text
    align-items: center
    justify-content: flex-start

.container
  display: inline-flex
  flex-direction: column
  padding: 0 .5rem

  &:not(:last-child)::after
    content: ''
    width: 100%
    height: 2px
    background-color: #33333371
    border-radius: 2px
    margin: .5rem 0
